import React, { useState } from "react";
import styled from "styled-components";

const StyledInput = styled.label`
    display: block;

    input, textarea {
        display: block;
        background-color: ${props => props.disabled ? "#ACACAC" : props.theme.white};
        pointer-events: ${props => props.disabled ? "none" : "auto"};
        color: ${props => props.theme.black};
        font-family: "Poppins", sans-serif;
        font-size: ${props => props.theme.texteNormal};
        border: none;
        border-radius: 3px;
        padding: 10px 10px;
        width: min-content;
        outline: none !important;
        margin-top: 5px;
        margin-bottom: 14px;
        margin-right: 30px;
        transition: background 0.5s ease-in-out;
    }

    @media (max-width: 680px) {
        input, textarea {
            width: calc(100vw - 150px);
            min-width: min-content;
        }
    }
`;

const Input = (props) => {
    const [value, setValue] = useState("");
    if (props.reset && value !== null && value !== undefined && value !== "") setValue("");

    const renderInput = props.type === "textarea"
        ? <textarea
            disabled={props.disabled}
            id={props.name}
            name={props.name}
            required={props.required}
            rows={props.row}
            cols={props.cols}
            value={value}
            onChange={(e) => setValue(e.target.value)}></textarea>
        : <input
            disabled={props.disabled}
            type={props.type}
            id={props.name}
            name={props.name}
            required={props.required}
            pattern={props.pattern}
            value={value}
            onChange={(e) => setValue(e.target.value)} />;

    return (
        <StyledInput disabled={props.disabled}>
            {props.label}
            {renderInput}
        </StyledInput>
    )
}

export default Input;