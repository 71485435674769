import React, { memo, useRef, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { gsap } from "gsap";

import PageLayout from "../layouts/PageLayout";
import Input from "../components/Input";

const seoParams = {
    title: "Me contacter",
    description: "Contactez-moi pour obtenir plus d'informations, échanger ou commencer à développer vos nouveaux projets !",
};

const SendButton = styled.button`
    pointer-events: ${props => props.disabled ? "none" : "auto"};
`

const MessageBox = styled.div`
    position: fixed;
    right: -100%;
    bottom: 50px;
    padding: 18px 20px;
    border-radius: 10px;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.black};
    transition: right 0.8s cubic-bezier(.7,-0.15,.26,1.05);

    p {
        margin: 0;
    }

    &.active {
        right: calc(0% + 50px);
    }
`

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`;

const Contact = memo(() => {
    const animationParent = useRef();
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });
    const [activeMessage, setMessageStat] = useState(false);

    const handleServerResponse = (statue, msg, form) => {
        setServerState({
            submitting: false,
            status: { statue, msg, reset: true }
        });
        if (statue) {
            form.reset();
            setServerState({
                submitting: false,
                status: { statue, msg, reset: false }
            });
        }

        setMessageStat(true);
        setTimeout(() => {
            setMessageStat(false);
        }, 5000);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        const data = new FormData(form);

        axios({
            method: "post",
            url: "/api/contact",
            data: data
        })
            .then(r => {
                handleServerResponse(true, "Merci ! Votre message à bien été envoyé et je vous contacterais dans les plus brefs délais !", form);
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    };

    useLayoutEffect(() => {
        const selector = gsap.utils.selector(animationParent);
        const animation = gsap.from(selector("h1, h2, form"), {
            duration: .8,
            ease: "power3.inOut",
            y: 20,
            opacity: 0
        });

        return () => animation.kill();
    }, []);

    return (
        <PageLayout seo={seoParams} ref={animationParent}>
            <h1 style={{ marginTop: 200 }}>Vous souhaitez démarrer un projet ?</h1>
            <h2 style={{ marginTop: 50 }}>Formulaire de contact</h2>
            <form onSubmit={handleSubmit} style={{ marginBottom: 100 }}>
                <Row>
                    <Input label="Prénom" type="text" name="prenom" required disabled={serverState.submitting} reset={serverState.status?.reset} />
                    <Input label="Nom" type="text" name="nom" required disabled={serverState.submitting} reset={serverState.status?.reset} />
                </Row>
                <Row>
                    <Input label="Téléphone" type="tel" pattern="[0-9]{10}" name="telephone" required disabled={serverState.submitting} reset={serverState.status?.reset} />
                    <Input label="Mail" type="email" name="mail" required disabled={serverState.submitting} reset={serverState.status?.reset} />
                </Row>
                <Input label="Votre message" type="textarea" name="message" required disabled={serverState.submitting} row="5" reset={serverState.status?.reset} />
                <SendButton disabled={serverState.submitting} type="submit">{serverState.submitting ? "Envoie en cours" : "Envoyer"}</SendButton>
            </form>
            <MessageBox className={activeMessage ? "active" : null}><p>{serverState.status?.msg}</p></MessageBox>
        </PageLayout>
    );
});

export default Contact;